<template>
  <div>
    <v-app :style="colorVapp">
      <onebox_toolbar
        :quicksearch="true"
        @inputdata="filteredItems"
        :parentfolder="parentfolder"
        @loadfile="getSourceSystem()"
        @callstorage="loadstorage()"
      ></onebox_toolbar>
      <v-content>
        <!-- <v-divider></v-divider> -->
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <br />
          <br />
          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <v-overlay :value="processloader_Sync" z-index="2" color="black" dark >
      <v-progress-circular indeterminate class="ma-2 text-center" size="64" color="white"></v-progress-circular>
      <br/>
      <span :style="colorProgress" v-if="counturltotal != 0">กำลังโหลดข้อมูล...</span>
      <span :style="colorProgress" v-if="counturltotal != 0">{{counturl}} / {{ counturltotal }}</span>
    </v-overlay>
        <!-- ขนาดมือถือ โทรศัพท์ -->
        <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
          <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
                <v-icon dark size="24" :color="color.theme">mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span  :style="headerPage" >{{ Labeldata }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-card>
        </v-list>
        <v-list nav :color="color.BG" v-else>
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">{{defaulticon}}</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span  :style="headerPage" >{{ Labeldata }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainer_inboxsystem">
          <v-card-text class="pa-0">
            <v-container  fluid>
              <v-layout row wrap justify-center>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <v-layout row wrap class="pl-3 pr-3">
                    <v-flex lg2 xs12>
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :color="color.theme"
                        :item-color="color.theme"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                      <br />
                    </v-flex>
                    <v-spacer></v-spacer>
                    <!-- หน้าจอธรรมดา -->
                    <v-flex  flex-column flex-sm-row justify-end v-if="resolutionScreen >= 400">
                      <!-- ความยินยอม -->
                      <div class="d-flex justify-end mb-4 px-1" v-if="$route.query.type === '2'">
                        <v-btn v-if="show_alert === false || consent_status === 'approve'" rounded dark :color="color.theme" @click="btnConsent()">{{ $t("myinboxPage.consent") }}</v-btn> 
                        <v-btn v-else class="button" style="border:red;border-style:solid;" rounded dark :color="color.theme" @click="btnConsent()">{{ $t("myinboxPage.consent") }}</v-btn>
                      </div>
                    <v-flex d-flex justify-end>
                      <div v-if="dataAccountActive.type === 'Citizen'" class="d-flex">
                        <div class="d-flex justify-end mb-4 px-1">
                          <v-btn
                            rounded
                            :color="color.theme"
                            style="color:white;" 
                            @click="btn_taxbox()"
                            >
                            Taxbox
                          </v-btn>
                        </div>
                        <!-- <div class="d-flex justify-end mb-4 px-1"  >
                          <v-btn
                          :disabled="$route.query.type === '2'  && consent_status === 'unapprove'" 
                          rounded
                          :color="color.theme"
                          style="color:white;" 
                          @click="btn_etax()"
                          >
                          {{ $t("consent.limit_receipt_of_etax") }}
                        <v-overlay
                                  v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                                  absolute
                                  color="#D5DBDD"
                                >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on" 
                          rounded
                          :color="color.theme"
                          style="color:white;" 
                          @click="show_alert = true"
                          >
                          {{ $t("consent.limit_receipt_of_etax") }}
                        </span>
                        </template>
                        <span>{{$t("consent.please_accept_consent")}}</span>
                        </v-tooltip>
                        </v-overlay>
                          </v-btn>
                        </div> -->
                        <!-- <div class="d-flex justify-end mb-4 px-1" >
                          <v-btn 
                          :disabled="$route.query.type === '2'  && consent_status === 'unapprove'"
                          rounded 
                          :color="color.theme"
                          style="color:white;" 
                          @click="opendialogresendfile = true"
                          >
                          {{ $t("resendfile.resend") }}
                          
                          <v-overlay
                              v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                              absolute
                              color="#D5DBDD"
                            >
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span 
                              v-bind="attrs" 
                              v-on="on"
                              @click="show_alert = true"
                            >
                            {{ $t("resendfile.resend") }}
                            </span>
                          </template>
                                <span>{{$t("consent.please_accept_consent")}}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-btn> 
                        </div> -->
                      </div>
                    <div v-else class="d-flex" >
                    <!-- การเลือกรับเอกสารอิเล็กทรอนิกส์/จำกัดการรับ e-Tax -->
                    <div class="d-flex justify-end mb-4 px-1">
                      <v-btn
                      :disabled="$route.query.type === '2'  && consent_status === 'unapprove'" 
                      rounded
                      :color="color.theme"
                      style="color:white;" 
                      @click="btn_etax()"
                      >
                      {{ $t("consent.limit_receipt_of_etax") }}
                    <v-overlay
                              v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                              absolute
                              color="#D5DBDD"
                            >
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on" 
                      rounded
                      :color="color.theme"
                      style="color:white;" 
                      @click="show_alert = true"
                      >
                      {{ $t("consent.limit_receipt_of_etax") }}
                    </span>
                    </template>
                    <!-- กรุณาเปิดรับความยินยอม -->
                    <span>{{$t("consent.please_accept_consent")}}</span>
                    </v-tooltip>
                    </v-overlay>
                    </v-btn>
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on" 
                          style="color:white;" 
                          rounded 
                          :color="color.theme" 
                          :disabled="$route.query.type === '2'  && consent_status === 'unapprove'" 
                          @click="btn_etax()"
                          >
                          {{ $t("consent.limit_receipt_of_etax") }}
                          </v-btn>
                        </template>
                        <span>กรุณาเปิดรับความยินยอม</span>
                      </v-tooltip>                     -->
                      </div>
                      <!-- ตั้งค่าการจัดการตาราง -->
                      <!-- <div class="d-flex justify-end mb-4 px-1" v-if="$route.query.type === '2'">
                        <v-btn rounded dark :color="color.theme" @click="opendialogmanageheader = true">{{ $t("myinboxPage.settingHeader.setting") }}</v-btn>                      
                      </div> -->
                      <!-- ส่งอีกครั้ง -->
                      <!-- <div class="d-flex justify-end mb-4 px-1">
                        <v-btn 
                          :disabled="$route.query.type === '2'  && consent_status === 'unapprove'"
                          rounded 
                          :color="color.theme"
                          style="color:white;" 
                          @click="opendialogresendfile = true"
                          >
                          {{ $t("resendfile.resend") }}
                          
                          <v-overlay
                              v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                              absolute
                              color="#D5DBDD"
                            >
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span 
                              v-bind="attrs" 
                              v-on="on"
                              @click="show_alert = true"
                            >
                            {{ $t("resendfile.resend") }}
                            </span>
                          </template>
                          กรุณาเปิดรับความยินยอม
                                <span>{{$t("consent.please_accept_consent")}}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-btn>
                      </div> -->
                    </div>
                    </v-flex>
                    </v-flex>
                    <!-- หน้าจอมือถือ -->
                    <v-flex justify-end v-else>
                    <!-- ความยินยอม -->
                      <div class="d-flex justify-end mb-4 px-1" v-if="$route.query.type === '2'">
                        <v-btn v-if="show_alert === false || consent_status === 'approve'" small rounded dark :color="color.theme" @click="btnConsent()">{{ $t("myinboxPage.consent") }}</v-btn>
                        <v-btn v-else small class="button" style="border:red;border-style:solid;" rounded dark :color="color.theme" @click="btnConsent()">{{ $t("myinboxPage.consent") }}</v-btn>                    
                      </div>
                    <v-flex d-flex justify-end>
                      <div v-if="dataAccountActive.type === 'Citizen'" class="d-flex">
                        <div class="d-flex justify-end mb-4 px-1" >
                          <v-btn
                            small
                            rounded
                            :color="color.theme"
                            style="color:white;" 
                            @click="btn_taxbox()"
                            >
                            Taxbox
                          </v-btn>
                        </div>
                        <!-- <div class="d-flex justify-end mb-4 px-1">
                          คอมเม้นตรงนี้
                        <v-btn small rounded dark :color="color.theme" @click="btn_etax()">{{ $t("consent.limit_receipt_of_etax") }}</v-btn>
                        <v-btn 
                        small
                        :disabled="$route.query.type === '2'  && consent_status === 'unapprove'"
                        rounded
                        :color="color.theme"
                        style="color:white;" 
                        @click="btn_etax()"
                        >
                        {{ $t("consent.limit_receipt_of_etax") }}
                            <v-overlay
                                v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                                absolute
                                color="#D5DBDD"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on" 
                                    rounded
                                    :color="color.theme"
                                    style="color:white;" 
                                    @click="show_alert = true"
                                    >
                                    {{ $t("consent.limit_receipt_of_etax") }}
                                  </span>
                                  </template>
                                  กรุณาเปิดรับความยินยอม
                                  <span>{{$t("consent.please_accept_consent")}}</span>
                                </v-tooltip>
                              </v-overlay>
                        </v-btn>
                      </div> -->
                      </div>
                      <div v-else class="d-flex">
                    <!-- การเลือกรับเอกสารอิเล็กทรอนิกส์/จำกัดการรับ e-Tax -->
                    <div class="d-flex justify-end mb-4 px-1">
                        <!-- <v-btn small rounded dark :color="color.theme" @click="btn_etax()">{{ $t("consent.limit_receipt_of_etax") }}</v-btn>-->
                      <v-btn 
                      small
                      :disabled="$route.query.type === '2'  && consent_status === 'unapprove'"
                      rounded
                      :color="color.theme"
                      style="color:white;" 
                      @click="btn_etax()"
                      >
                      {{ $t("consent.limit_receipt_of_etax") }}
                          <v-overlay
                              v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                              absolute
                              color="#D5DBDD"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                  v-bind="attrs"
                                  v-on="on" 
                                  rounded
                                  :color="color.theme"
                                  style="color:white;" 
                                  @click="show_alert = true"
                                  >
                                  {{ $t("consent.limit_receipt_of_etax") }}
                                </span>
                                </template>
                                <!-- กรุณาเปิดรับความยินยอม -->
                                <span>{{$t("consent.please_accept_consent")}}</span>
                              </v-tooltip>
                            </v-overlay>
                    </v-btn>
                      </div>
                      <!-- ตั้งค่าการจัดการตาราง -->
                      <!-- <div class="d-flex justify-end mb-4 px-1" v-if="$route.query.type === '2'">
                        <v-btn rounded dark :color="color.theme" @click="opendialogmanageheader = true">{{ $t("myinboxPage.settingHeader.setting") }}</v-btn>                      
                      </div> -->
                      <!-- ส่งอีกครั้ง -->
                      <!-- <div class="d-flex justify-end mb-4 px-1" v-if="$route.query.type === '2'">
                        <v-btn 
                          :disabled="$route.query.type === '2'  && consent_status === 'unapprove'"
                          small  
                          rounded 
                          style="color:white;" 
                          :color="color.theme" 
                          @click="opendialogresendfile = true"
                          >
                          {{ $t("resendfile.resend") }}
                          <v-overlay
                              v-if="$route.query.type === '2'  && consent_status === 'unapprove'"
                              absolute
                              color="#D5DBDD"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span 
                                    v-bind="attrs" 
                                    v-on="on"
                                    @click="show_alert = true"
                                  >
                                  {{ $t("resendfile.resend") }}
                                  </span>
                                </template>
                                  กรุณาเปิดรับความยินยอม
                                <span>{{$t("consent.please_accept_consent")}}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-btn>                      
                      </div> -->
                      </div>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6 elevation-1">
                        <v-list-item v-if="paginatedData.length === 0">
                        <v-list-item-content class="text-center" v-if="resolutionScreen >= 400">
                          <v-list-item-title v-text="$t('myinboxPage.Nodata')"></v-list-item-title>
                          <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- หน้าจอมือถือ -->
                      <v-list-item-content v-else>
                        <v-card-text class="mt-4 mb-4">
                          <v-img
                            :src="require('../assets/img/icon_empty_data.png')"
                            max-width="132"
                            max-height="150"
                            class="mx-auto"
                          />
                          <v-list-item-title
                            class="text-center mt-8"
                            v-text="$t('myoutboxPage.Nodata')"
                            :style="
                              'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                                color.theme +
                                ';'
                            "
                          ></v-list-item-title>
                        </v-card-text>
                      </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                      v-else
                        v-for="item in paginatedData"
                        :key="item.title"
                        @dblclick="gotoSourceSystemIndex(item.system,item.system_id)"
                        @click="gotoSourceSystemIndex(item.system,item.system_id)"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar>
                          <v-icon large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content @dblclick="gotodirectory()">
                          <v-list-item-title v-text="item.system"></v-list-item-title>
                          <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if=" item.sum_receive > 0 "
                            class="ma-2"
                            color="red"
                            text-color="white"
                          >{{ item.sum_receive}}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- 
                      <v-divider inset></v-divider>-->
                    </v-list>
                  </div>

                  <div v-else>
                    <v-data-table
                      :headers="headers"
                      :items="systemdirectory"
                      item-key="name"
                      :no-data-text="$t('myinboxPage.Nodata')"
                      :show-select="false"
                      :search="search"
                      class="elevation-1"
                      item-selected
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <!-- <template v-slot:header.system_type="{ header }">
                         <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ header.text }}</span>-->
                      <!-- </template> --> 
                      <template v-slot:[`header.system`]="{ header }">
                        <span
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr
                          @click="gotoSourceSystemIndex(props.item.system,props.item.system_id)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            <v-icon x-large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                          </td>
                          <td>{{ props.item.system}}</td>
                          <td class="text-right">
                            <v-chip
                              v-if="props.item.sum_receive > 0"
                              class="ma-2"
                              color="red"
                              text-color="white"
                            >{{ props.item.sum_receive}}</v-chip>
                          </td>
                        </tr>
                      </template>
                      <!-- <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @contextmenu="test($event,item)"
                          @dblclick="gotoSourceSystemIndex(item.system)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            <v-icon x-large color="primary" style="font-weight: 100">move_to_inbox</v-icon>
                          </td>
                          <td>{{ item.system}}</td>
                        </tr>
                      </tbody>
                      </template>-->
                    </v-data-table>
                  </div>
                  <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="systemdirectory.length > 0">
                      <v-pagination v-model="page" :length="pageCount || 0" :color="color.theme"></v-pagination>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>

          <dialogalertresend
            :show="opendialogalertresend"
            @closedialog="opendialogalertresend = false"
          ></dialogalertresend> 
          </v-card-text>
          <dialogmanageheader
            :show="opendialogmanageheader"
            @closedialog="opendialogmanageheader = false"
          ></dialogmanageheader>
          <dialogresendfile
            :show="opendialogresendfile"
            @closedialog="opendialogresendfile = false"
          ></dialogresendfile>
          <dialogconsent
            :show="opendialogconsent"
            :dataconsent="listconsent"
            @close="opendialogconsent = false , check() , loadSourceSystem(dataAccountActive.business_info['business_id'],'2')"
            @submit="submitConsent"
          ></dialogconsent>
          <dialogalertcontactadmin
            :show="opendialogalertcontactadmin"
            @close="opendialogalertcontactadmin = false , loadSourceSystem(dataAccountActive.business_info['business_id'],'2')"
          ></dialogalertcontactadmin>
          <dialogconsentclose
            :show="opendialogconsent_colse"
            @close="opendialogconsent_colse = false, loadSourceSystem(dataAccountActive.business_info['business_id'],'2')"
          ></dialogconsentclose>
          <dialogconsentetax
            :show="opendialogconsentetax"
            :dataconsent="listconsent"
            :datataxid="data_taxid"
            @close="opendialogconsentetax = false, loadSourceSystem(dataAccountActive.business_info['business_id'],'2')"
          ></dialogconsentetax>
          <dialogtaxbox
            :show="opendialogtaxbox"
            @closedialog="closedialogtaxbox"
            @closedialogupdate_inbox="closedialogupdate_get_inbox"
          >
          </dialogtaxbox>
          
        </v-card>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const dialogmanageheader = () => import("../components/optional/dialog-manageheaderinbox.vue");
const dialogresendfile = () => import("../components/optional/dialog-resendfileinbox.vue");
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
const dialogalertresend = () => import("../components/optional/dialog-alertresend.vue");
const dialogconsent = () => import("../components/optional/dialog-consent.vue");
const dialogalertcontactadmin = () => import("../components/optional/dialog-alertcontactadmin.vue");
const dialogconsentclose = () => import("../components/optional/dialog-consent-close.vue");
const dialogconsentetax =() => import("../components/optional/dialog-consent-etax.vue");
const dialogtaxbox = () => import("../components/optional/dialog-taxbox.vue")
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  name: "myinbox_system",
  components: { 
    onebox_toolbar,
    dialogresendfile,
    dialogalertresend,
    dialogmanageheader,
    dialogconsent,
    dialogalertcontactadmin,
    dialogconsentclose,
    dialogconsentetax,
    dialogtaxbox
  },
  data: function() {
    return {
      counturl:0,
      counturltotal:0,
      processloader_Sync:false,
      opendialogtaxbox: false,
      show_alert:false,
      consent_status:"",
      data_taxid:[],
      checkbtn_:0,
      opendialogconsent_colse:false,
      listconsent: [],
      isConsentWait: "",
      isConsentAllUnapprove: false,
      opendialogalertcontactadmin: false,
      opendialogconsent: false,
      opendialogconsentetax: false,
      opendialogmanageheader: false,
      opendialogresendfile: false,
      opendialogalertresend:false,
      search: '',
      defaulticon: "",
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      nodata: this.$t("myinboxPage.Nodata"),
      loaddataprogress: true,
      systemdirectory: [],
      firstopenpage: true,
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "myinboxPage.HeaderSourceSystem.systemname",
          align: "left",
          value: "system",
          width: "83%",
          sortable: false
        },
        {
          text: "",
          align: "left",
          value: "sum_receive",
          width: "10%",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service","color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata() {
      let companyname = ''
      if (this.$route.query.type === "2") {
        this.defaulticon = "business";
        this.getSourceSystem();
        if(this.$t('default') === 'th'){
          if (this.$route.query.nameth === undefined || this.$route.query.nameth === null || this.$route.query.nameth === ""){
            companyname = this.dataAccountActive['business_info']['first_name_th'] || "-"
          }else{
            companyname = this.$route.query.nameth
          }
        }else{
          if (this.$route.query.nameen === undefined || this.$route.query.nameen === null || this.$route.query.nameen === ""){
            companyname = this.dataAccountActive['business_info']['first_name_eng'] || "-"
          }else{
            companyname = this.$route.query.nameen
          }
        }
        return this.$t("toolbar.myinbox.Businessname") + ' ' + companyname;
      } else {
        this.defaulticon = "face";
        this.getSourceSystem();
        return this.$t("toolbar.myinbox.Personalname");
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.systemdirectory.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.systemdirectory.slice(start, start + this.size);
    },
    showdatafilter() {
        // console.log(search);
        return this.systemdirectory.filter(item => {
        return item.system.toLowerCase().indexOf(this.search.toLowerCase()) > -1

      })
    },headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    }
  },
  methods: {
    check(){
      if(this.checkbtn_){
        this.opendialogconsent_colse = true
      }      
    },
    openalert(){
      let check = VueCookies.get('checkalertresend');
      let checkonetime = VueCookies.get('checkalertresendonetime');
      // console.log("======",check);
      if (this.systemdirectory.length == 0 && this.firstopenpage === true) {
        if(check === "false" || check === null){
          if (this.$route.query.type === "2") {
            if (this.listconsent.length === 0) {
              if(checkonetime == false){
                this.opendialogalertresend = true;
              }
            }
            this.firstopenpage = false;
          }
        }
      }
    },
     filteredItems(search){
      this.search=search
    },
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    },
    getSourceSystem() {
      if (this.$route.query.type === "2") {
        this.getConsent().then(() => {
          if (this.isConsentWait || this.isConsentAllUnapprove) {
            // if (this.role_level === "True") {
              this.opendialogconsent = true;
            // } else {
            //   this.opendialogalertcontactadmin = true;
            // }
            this.loaddataprogress = false;
          } else {
            if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === '') {
              this.loadSourceSystem(this.dataAccountActive.business_info["business_id"],"2");
            } else {
              this.loadSourceSystemV2(this.$route.query.biz,"2",this.$route.query.fromdate,this.$route.query.todate);
            }
          }
        })
      } else {
        this.firstopenpage = true;
        this.loadSourceSystem(this.dataAccountId, "1");
      }
    },
    btnConsent() {
      // if (this.role_level === "True") {
        // dialog ความยินยอมแบบเก่า
        this.opendialogconsent = true;
        // dialog ความยินยอมแบบใหม่
        // this.get_accept_inbox();
        // this.opendialogconsentetax = true;

        
      // } else {
      //   this.opendialogalertcontactadmin = true;
      // }
    },
    btn_etax(){
      this.get_accept_inbox();
      this.opendialogconsentetax = true;
    },
    btn_taxbox () {
      this.opendialogtaxbox = true
    },
    closedialogtaxbox () {
      this.opendialogtaxbox = false
    },
    closedialogupdate_get_inbox () {
      this.opendialogtaxbox = false
      this.loadSourceSystem(this.dataAccountId, "1");
    },
    async get_accept_inbox(){
      this.data_taxid = [];
      console.log("GET_TAX_ID");
      let payload = {
        business_id:this.dataAccountActive.business_info.business_id,

      }
        let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_accept_inbox", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            this.data_taxid = res.data.data;
            console.log("GET TAX ID", this.data_taxid);
          }else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });
    },
    submitConsent() {
      this.loadSourceSystem(this.dataAccountActive.business_info["business_id"],"2");
      this.getConsent();
    },
    async getConsent() {
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        one_biz_id: this.dataAccountActive.business_info.id,
      };
      console.log("payload getConsent", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_accept_system", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้านี่จ้าา", res.data); 
            console.log("เข้านี่จ้าา-", res.data.data.length);            
            this.isConsentWait = res.data.data.filter(f => f.status === "wait").length > 0
            this.isConsentAllUnapprove = res.data.data.filter(f => f.status === "unapprove").length === res.data.data.length && (res.data.data.length !== 0)
            this.checkbtn_ = res.data.data.filter(f => f.status === "wait").length === res.data.data.length
            this.listconsent = res.data.data;
            for(let i=0; i<this.listconsent.length; i++){
              this.consent_status = this.listconsent[i].status
            console.log("this.listconsent",this.listconsent[i].status);
            }
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });
    },
    async loadSourceSystem(_accountid, _type) {
      this.loaddataprogress = true;
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/getmyinbox",
          payload,
          { withCredentials: false ,
          headers: { Authorization: auth.code },}
        );
        this.loaddataprogress = false;

        if (response.data.status === "OK") {
          this.systemdirectory = response.data.result;
          // this.openalert();
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        this.loaddataprogress = false;
      }
    },

    async loadSourceSystemV2(_accountid, _type, _fromdate, _todate) {
      this.loaddataprogress = true;
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        startdate: this.formatDate(_fromdate),
        enddate: this.formatDate(_todate)
      };
      try {
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v2/getmyinbox",
          payload,
          { withCredentials: false ,
          headers: { Authorization: auth.code },},
        );
        console.log(response);
        this.loaddataprogress = false;

        if (response.data.status === "OK") {
          this.systemdirectory = response.data.result;
          // this.openalert();
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        this.loaddataprogress = false;
      }
    },

    gotoSourceSystemIndex(_systemname,system_id) {
      console.log("system_id",system_id);
      this.$router.push({
        path: "/myinbox-sourcesystem",
        query: { type: this.$route.query.type, sourcesystem: _systemname,systemid:system_id, biz: this.$route.query.biz, fromdate: this.$route.query.fromdate, todate: this.$route.query.todate, nameen: this.$route.query.nameen, nameth: this.$route.query.nameth }
      });
      VueCookies.set('checkalertresendonetime', true);
      VueCookies.set('checkalertcondition', true);
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    async fn_taxboxresend(){
      this.counturl = 0;
      this.processloader_Sync = true;
      let idc = VueCookies.get("idc");
      // let idc = window.btoa(VueCookies.get("idc"));
      let idc_dcode =  window.atob(VueCookies.get("idc"));
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      
      let payload = {
        id_card: idc_dcode,
        oneid_user_id: user_id,
      };
      console.log("payload getConsent", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/taxbox/get_file_from_id_card", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("res.data.data.length",res.data.data.length);
            if(res.data.data.length === 0){
              this.processloader_Sync = false;
            }else{
              console.log("data",res.data.data);
              this.counturltotal = res.data.data.length;
              this.fn_uploadtaxbox(res.data.data)
            }
          } else {
            if(res.data.errorCode === "ER501"){
              if(res.data.errorMessage === 'ID Card was not match with this user'){
                Toast.fire({
              icon: "warning",
              title: "เลขบัตรประชาชนไม่ตรงกับผู้ใช้งาน",
            });
              }
            }else{
              Toast.fire({
                icon: "error",
                title: res.data.errorMessage,
              });
            }
            this.processloader_Sync = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.processloader_Sync = false;
        });

    },
    async fn_uploadtaxbox(listtaxbox){
      for(let i=0; i < listtaxbox.length; i++){
        let url = listtaxbox[i]
        await this.axios
        .get(url)
        .then((res) => {
          if(res.data.status === 'OK'){
            this.counturl++
          }
        })
        if(i === this.counturltotal - 1){
          this.processloader_Sync = false;
          this.loadSourceSystem_taxbox();

        }
      }
    },
    async loadSourceSystem_taxbox(_accountid, _type) {
      this.loaddataprogress = true;
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid_userinfo"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      let payload = {
        receiver_id: user_id,
        receiver_type: "1"
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/getmyinbox",
          payload,
          { withCredentials: false ,
          headers: { Authorization: auth.code },}
        );
        this.loaddataprogress = false;

        if (response.data.status === "OK") {
          let objIndex = response.data.result.findIndex((obj) => obj.system === "ETAX");
          console.log("objIndex",objIndex);
          if(objIndex !== -1){
            this.$router.push({ path: "/myinbox-sourcesystem", query: { type: 1 , sourcesystem:"ETAX" , systemid:response.data.result[objIndex].system_id} });
          }else{
            this.$router.push({ path: "/myinbox-system", query: { type: 1 }});
          }
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        this.loaddataprogress = false;
      }
    },
  },
  mounted() {
    let checktaxbox = VueCookies.get("taxbox_check");
    if(checktaxbox === "true" && this.$route.query.type === 1){
      this.fn_taxboxresend();
    }
  }
};
</script>

<style>
#thiscontainer_inboxsystem {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  
}
</style>